
var
    GENERAL_CONFIG= {
        APP_NAME: 'breakoutplay',
        APP_VERSION: '0.1'
    },
    API_CONFIG= {
    API_URL: "https://server.breakoutplay.com/",
        HOST: "https://www.breakoutplay.com/",
        DEFAULT_PICTURE : "public/img/default_player.png",
        EVENT_DEFAULT_PICTURE: "public/img/events/default_event.png",
        FACEBOOK_CONFIG:1046647238709704
    };