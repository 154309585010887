/**
 * Created by Andrey on 5/26/16.
 */

    var FaceBookModule = function(){
        this.fbInit();
    };

    FaceBookModule.prototype.fbInit = function(){
        var self = this;
        $.ajaxSetup({cache: true});
        $.getScript('//connect.facebook.net/en_US/sdk.js', function () {
            FB.init({
                appId: API_CONFIG.FACEBOOK_CONFIG,
                xfbml: true,
                version: 'v2.5'
            });
            //FB.getLoginStatus(function(response) {
            //    if (response.status === 'connected') {
            //        self.userID = response.authResponse.userID;
            //        self.access_token =response.authResponse.accessToken;
            //    }
            //},{ scope: 'email, user_friends, publish_actions'});
        });
    };

    FaceBookModule.prototype.getUserData = function(){
        var self = this;
        if(!self.userID || !self.access_token){
            FB.login(function(response) {
                if (response.status === 'connected') {
                    self.userID = response.authResponse.userID;
                    self.access_token =response.authResponse.accessToken;
                    self.FbLogin()
                }
            },{ scope: 'email, user_friends, publish_actions'})

        }else{
            self.FbLogin()
        }

    };

    FaceBookModule.prototype._getUserData = function(){
        var self = this;
        FB.api('/me',{
            fields: ['first_name', 'last_name', 'email', 'picture']
        }, function(res) {
            console.log("resresres",res);
            self.email=res.email;
            self.first_name = res.first_name;
            self.last_name = res.last_name;
            self.FbAuth();
        });
    };

    FaceBookModule.prototype.FbLogin = function(){
        var self = this;
        if(self.userID && self.access_token){
            $.ajax({
                type:'GET',
                url: API_CONFIG.API_URL + "thor/facebook-login",
                data: JSON.stringify({
                    facebook_id: self.userID
                }),
                success:function(data) {
                    self._getUserData();
                },
                error:function(err){
                }
            })
        }else{
            self.getUserData()
        }



    };
    FaceBookModule.prototype.FbAuth = function(){
        var self = this;
        $.ajax({
            type:'POST',
            url: API_CONFIG.API_URL + "thor/facebook-login",

            contentType: "application/json;charset=utf-8",
            processData:false,
            data: JSON.stringify({
                facebook_id: self.userID,
                access_token: self.access_token,
                email:self.email
            }),
            success:function(responce) {
                var data = {
                    access_token:responce.Authorisation,
                    email:self.email,
                    first_name:self.first_name,
                    last_name:self.last_name,
                    id:self.userID
                };
                var json_data =JSON.stringify(data);
                document.location.href = API_CONFIG.HOST+"app/fb_auth/"+json_data;
            },
            error:function(err){
                if(err.status == 400 || err.status == 405) {
                    document.location.href = API_CONFIG.HOST+ "app/facebook/user_exist";
                }

            }

        })
    }
